.main {
  height: 100%;
  width: 100%;
  row-gap: 20px;
  font-family: 'berryRotunda';
}

.App-header {
  display: block;
}

.App-logo {
  max-width:30%;
  margin: auto;
  display:block;
  text-align: center;
  margin-bottom: 10px;
}

@font-face {
  font-family: 'berryRotunda';
  src: url(Berry\ Rotunda.ttf);
}

html {
  background: url("background.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  letter-spacing: 1px;
}

.link-container {
  text-align: center;
}

.links {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  font-size:small;
  color:burlywood;
  background-color:rgb(12, 3, 3);
}  

a {
  text-decoration: none;
  color: white;
  transition: color 0.5s;
}

a:hover {
  color: #000;
  background: #fff;
}

ion-icon {
  font-size: 15px;
}

.link-title {
  text-align: center;
}

.link-logo {
  width:15px;
}

.title-2 {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 7px;
  font-family: 'berryRotunda';
  letter-spacing: normal;
  font-size: large;
}

.title-3 {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 30px;
  letter-spacing: normal;
  font-size: small;
}


.bio-container {
  text-align: center;
  max-width: 70%;
  margin:auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 8px;
  width: 80%;
  border-radius: 20px;
  font-size:small;
  color:burlywood;
  background-color:rgb(12, 3, 3);
  line-height: 18px;
  font-family: 'Lora';
}

.bandphoto {
  max-width: 60%;
  margin:auto;
  height: 100%;
}

.link-row {
  text-align: center;
  margin-bottom: 15px;
}

.bio-text {
  margin:auto;
  max-width: 600px;
  line-height: 18px;
  font-family: 'Lora';
}

.bio-paragraph {
  display: block;
  margin-bottom: 10px;
}

.video-container {
  text-align: center;
  max-width: 70%;
  margin:auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 8px;
  width: 80%;
  border-radius: 20px;
  font-size:small;
  color:burlywood;
  background-color:rgb(12, 3, 3);
  line-height: 25px;
}

.video-title {
  margin-top: 50px;
  margin-bottom: 10px;
}

.gallery-pic {
  max-width: 50%;
  display: block;
  text-align: center;
  margin:auto;
}

.gallery {
  text-align: center;
  max-width: 70%;
  margin:auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 8px;
  width: 80%;
  border-radius: 20px;
  font-size:small;
  color:burlywood;
  background-color:rgb(12, 3, 3);
  line-height: 25px;
}

.link-button {
  margin:auto;
  margin-top: 10px;
  margin-bottom: 40px;
  width:150px;
  text-align: center;
  padding-top: 4px;
  display:block;
  border-radius: 5px;
  font-size:small;
  color:burlywood;
  background-color:rgb(77, 31, 10);
} 

.member-presentation {
  margin-top: 20px;
  margin-bottom: 50px;
}

.youtube{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.youtube-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}